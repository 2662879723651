@import "../../styles.scss";

.home-section1{
    .container{
        max-width: 1240px;
        position: relative;
        z-index: 1;
        padding-left: 15px;
        padding-right: 15px;
    }
    background-color: $grayf7f7;
    min-height: 620px;
    display: flex;
    align-items: center;
    position: relative;
    .fixello-app-chips{
        width: 205px;
        height: 52px;
        padding: 4px 8px 4px 8px;
        border-radius: 80px;
        gap: 12px;
        background-color: $white;
        box-shadow: 13px 8px 19px 0px rgba(0, 0, 0, 0.04);
        font-size: 24px;
        position: relative;
        .btnflwr{
            position: absolute;
            right: -30px;
            top: 0;
        }
    }
    .hm-banner-heading{
        line-height: 64px;
        color: black;
        .orange-span{
            color: $orange;
        }
    }
    .hm-banner-left-content{
        width: 42%;
    }
    .home-banner-right-image {
        // position: absolute;
        // right: 0%;
        width: 58%;
    }
    .homebannerbgimages {
        position: absolute;
        left: 0;
        bottom: 0;
    }
   
    @media screen and (max-width: 768px){
        min-height: auto;
        display: block;
        padding: 70px 0px;
        .hm-banner-heading {
            line-height: 40px;
            font-size: 30px;
        }
         .home-banner-right-image {
            // position: absolute;
            // right: 0%;
            width: 100%;
            position: relative;
        }
        .homebannerbgimages {
            width: 28%;
        }
        .hm-banner-left-content{
            width: 100%;
        }
        .storeIcon-a {
            width: 30% !important;
        }
    }
    .storeIcon-a{
        width: 150px;
    }
    .storeIcon {
        width: 100%;
    }
    // @media (min-width: 1600px) and (max-width:2500px){
    //      .home-banner-right-image {
    //         right: 18%;
    //         width: 720px;
    //     }
    //   .hm-banner-left-content {
    //         width: 605px;
    //     }
    // }
    @media (min-width: 820px) and (max-width: 1250px){
        .container{
            max-width: 1110px;
            .storeIcon-a {
                width: 30%;
            }
        }
    }
}
.home-section2{
    padding-top: 100px;
    padding-bottom: 60px;
    .what-we-do-sectoion{
        background-image: url(../../assets/images/structure/what-we-do-bg-image.png);
        min-height: 571px;
        background-color: #5973C4;
        border-radius: 20px;
        padding: 74px 40px;
        .w-w-d-image-box{
            min-width: 38%;
            max-width: 38%;
            height: auto;
            position: relative;
            .whatwedoimages{
                width: 100%;
            }
            .btnflwr{
                position: absolute;
                right: -25px;
                top: -25px;
            }
        }
        .w-w-d-content-box{
            min-width: 62%;
            max-width: 62%;
            padding-left: 60px;
            
            .h4{
                font-family: 'Poppins-Medium';
                color: $white;
                font-weight: 500 !important;
            }
            .h2{
                font-family: 'Poppins-Medium';
                color: $white;
            }
            .p3{
                color: $white;
                line-height: 28px;
            }
        }
    }
    @media screen and (max-width: 768px){
        .what-we-do-sectoion {
            .w-w-d-content-box {
                min-width: 100%;
                max-width: 62%;
                padding-left: 0;
            }
            .w-w-d-image-box {
                min-width: 100%;
                max-width: 100%;
                margin-bottom: 15px;
            }
        }
    }
    @media screen and (max-width: 768px){
        padding-top: 50px;
    }
}
.listul{
    padding-left: 0;
    .listli{
        list-style: none;
        color: $white;
        line-height: 28px;
        margin-bottom: 16px;
        position: relative;
        padding-left: 40px;
        font-size: 14px;
        .listIcon{
            position: absolute;
            left: 0;
            top: 5px;
        }
    }
}
.home-section3{
    padding-top: 30px;
}
.fixello-personal-box{
    border: 1px solid #EEEEEE;
    border-radius: 12px;
    padding: 40px 40px 10px 40px;
    min-height: 380px;
    .fixello-per-cor-Icon {
        margin-bottom: 15px;
        margin-top: -80px;
        transition: transform 0.5s ease-in-out;
        &:hover {
        transform: rotate(360deg);
        }
    }
    .h2{
        font-family: 'Poppins-Bold';
        font-weight: 700;
        margin-bottom: 0;
        color: $black;
    }
    .p2{
        color: $black;
    }
    .listli{
        color: $black;
        .listIcon {
            top: 0;
            bottom: 0;
            margin: auto;
        }
    }
    @media screen and (max-width: 768px){
        margin-bottom: 50px;
    }
}
.home-section5{
    background-color: #F9F9F9;
    padding:30px 0;
    .whyfixelloIcon {
        position: absolute;
        left: 0;
        top: 0;
        margin: auto;
        bottom: 0;
    }
    .p-accordion .p-accordion-header .p-accordion-header-link {
        padding: 12px 0;
        border: none;
        color: $black;
        background: transparent;
        font-weight: 700;
        border-radius: 0%;
        position: relative;
        .p-accordion-header-text {
            min-height: 66px;
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: 80px;
            font-size: 16px;
            font-family: 'Poppins-SemiBold';
            font-weight: 600;
        }
        .p-accordion-toggle-icon {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            display: block;
            height: 17px;
        }
    }
    .p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
        background: transparent;
        border-color: transparent;
        color: $black;
    }
    .p-accordion-tab-active{
        span.p-accordion-header-text {
            color: $blue;
        }
    }
    .p-accordion .p-accordion-content {
        padding: 10px 0 24px 0;
        border: transparent;
        background: transparent;
        color: #000;
    }
    .p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
        outline: 0 none;
        outline-offset: 0;
        box-shadow: none;
    }
    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link{
        border-color: transparent;
        background: transparent;
        color: $black;
    }
}
.home-section6{
    padding-top: 100px;
    padding-bottom: 100px;
    @media screen and (max-width: 768px){
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .storeIcon {
        width: 150px;
    }
}
.container-1269{
    max-width: 1269px;
}
.container-1216{
    max-width: 1216px;
}
.container-1366{
    max-width: 1366px;
    padding-left: 0;
    padding-right: 0;
}
.container-1165{
    max-width: 1165px;
}
.sliderFor{
    height: 505px;
    width: 100%;
    background-image: url(../../assets/images/structure/forsliderbg-orange.png);
    background-size: 100% 384px;
    background-position: center bottom;
    background-repeat: no-repeat;
    .forClientSlides{
        width: 308px;
        margin: 0 auto;
        img{
            width: 100%;
        }
        @media screen and (max-width: 768px){
            width: 100%;
            padding: 26px 44px;
            img.clientPhoneImage{
                width: 100%;
            }
        }
    }
    .slick-dots {
        li {
            width: 10px;
            height: 10px;
            button {
                width: 10px;
                height: 10px;
                padding: 1px;
                border: 1px solid transparent;
                border-radius: 50px;
                display: block;
                &:before {
                    font-size: 0;
                    line-height: normal;
                    width: 6px;
                    height: 6px;
                    opacity: 1;
                    background-color: rgba(251, 176, 59, 0.4);
                    position: relative;
                    display: block;
                    border-radius: 50px;
                }
            }
            &.slick-active{
                button {
                    border: 1px solid rgba(180, 197, 255, 1);
                }
            }
        }
    }
    @media screen and (max-width: 768px){
        height: auto;
        background-size: 100% 310px;
        margin-bottom: 40px;
    }
}
.forClient-content{
    .h6{
        font-family: 'Poppins-SemiBold';
        font-weight: 600;
        .forIcon{
            margin-right: 12px;
        }
    }
}
.forClient-content{
    padding-left: 60px;
    @media screen and (max-width: 768px){
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
}
.forClient-content {
    padding-left: 76px;
    padding-right: 54px;
    .p2{
        width: 465px;
    }
    @media screen and (max-width: 768px){
        width: 100%;
        .p2{
            width: 100%;
        }
    }
}
.grayAlertBox{
    background-color: #F7F7F7;
    padding: 11px 15px;
    border-radius: 6px;
    .listul{
        margin: 0;
        .listli{
            color: $black;
            .listIcon {
                bottom: 0;
                top: 0;
                margin: auto 0;
            }
        }
    }
}
.forsliderRow{
    padding-left: 65px;
    // height: 505px;
    width: 100%;
    background-image: url(../../assets/images/structure/forslider-border-image.png);
    background-size: 373px auto;
    background-position: left bottom 88px;
    background-repeat: no-repeat;
    &.forServiceProviders{
        padding-left: 0;
        background-position: right bottom 40px;
        padding-right: 40px;
        margin-top: 60px;
            .sliderFor {
                background-image: url(../../assets/images/structure/forsliderbg-blue.png);
            }
            .forClient-content {
                padding-right: 80px;
                padding-left: 65px;
                @media screen and (max-width: 768px){
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }
            @media screen and (max-width: 768px){
                padding-left: 0;
                padding-right: 0;
                margin-top: 60px;
            }
        }
    @media screen and (max-width: 768px){
        height: 100%;
        background-image: none;
        padding-left: 0;
    }
}
.home-section7{
    background-color: #FFEEE2;
    padding: 47px 0 120px 0;
    .ourClientDetail{
        background-color: $white;
        box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 40px;
        min-height: 318px;
        .clientImage{
            border-radius: 100%;
            border: 3px solid rgba(79, 106, 191, 0.3);
            width: 80px;
            max-width: 80px;
            height: 80px;
        }
    }
    .slick-slide {
        padding: 0 20px;
    }
    .sliderArrowWhitecircle{
        .slick-arrow {
            right: 160px !important;
            left: auto !important;
            bottom: -92px !important;
            top: auto;
            &.slick-prev {
                right: 245px !important;
            }
        }
    }
}
.home-section8{
    padding: 100px 0;
    .contactUsBox{
        padding: 60px;
        border: 1px solid #EEEEEE;
        border-radius: 20px;
    }
    .contactDEatil{
        padding-right: 75px;
        @media screen and (max-width: 768px){
            padding-right: 0;
        }
        .h6{
            font-size: 14px;
            font-family: 'Poppins-Bold';
            font-weight: 700;
        }
    }
    @media screen and (max-width: 768px){
        padding: 50px 0;
        .contactUsBox {
            padding: 40px 20px;
        }
    }
}
.sliderArrowWhitecircle{
    .slick-slide{
        padding: 0 12px;
    }
    .slick-arrow {
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
        width: 52px;
        height: 52px;
        border-radius: 50px;
        background-color: #fff;
        z-index: 1;
        &:before{
            content: "";
            font-size: 0 !important;
            border: solid black !important;
            border-width: 0 2px 2px 0 !important;
            display: inline-block !important;
            padding: 4px !important;
        }
        &.slick-next {
            right: -55px;
            @media screen and (max-width: 768px){
                right: 0;
            }
            &:before{
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }

        }
        &.slick-prev {
            left: -55px;
            @media screen and (max-width: 768px){
                left: 0;
            }
            &:before{
                transform: rotate(135deg);
            -webkit-transform: rotate(135deg);
            }
        }
    }
}

.categorBox{
    cursor: pointer;
    .p2{
        text-transform: capitalize;
    }
    .categroyimagebox {
        height: 240px;
        border-radius: 4px;
        overflow: hidden;
        .categroyimage {
            width: 100%;
            height: 100%;
            object-fit: fill;
            transition: 0.5s ease;
            border-radius: 4px;
        }
        &:hover{
            .categroyimage{
                -webkit-transform: scale(1.1);
                -ms-transform: scale(1.1);
                transform: scale(1.1);
                transition: 0.5s ease;
            }
        }
    }
    &:hover{
        .p2{
            color: $orange;
        }
    }
}
.home-section4{
    padding-top: 100px;
    padding-bottom: 100px;
    .h3{
        text-transform: capitalize;
    }
    .h2{
        color: $orange;
        text-transform: capitalize;
    }
    .h3{
        font-size: 20px;
        font-family: 'Poppins-Medium';
        font-weight: 500;
    }
    @media screen and (max-width: 768px){
        padding-top: 0;
        padding-bottom: 50px;
    }
}
.scroll-to-top-button {
    position: fixed;
    bottom: 65px;
    right: 130px;
    z-index: 9;
}
  
  /* Customize the button appearance */
  .p-button.p-button-icon-only.p-button-rounded {
    border-radius: 50%;
    height: 40px;
    width: 40px;
    background: #fff;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.04) !important;
    border: 1px solid #FBB03B;
    color: #FBB03B;
}
  .whatsapp {
    position: fixed;
    right: 0;
    bottom: -35px;
    cursor: pointer;
    z-index: 9;
}


/* For Arabic */
