@import '../../../styles.scss';
.header-wrapper{
    .header{
        padding: 9px 0;
        box-shadow: 0px 4px 54px 0px rgba(0, 0, 0, 0.05);
        background-color: #fff;
        .container{
          width: 100%;
          max-width: 1140px;
          margin: auto;
          .navbar {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background-color: transparent;
            color: white;
            padding: 0;
            .navbar-logo {
              font-size: 1.5rem;
            }
            .nav-links {
              list-style-type: none;
              display: flex;
              justify-content: end;
              align-items: center;
              width: 80%;
              transition: max-height 0.3s ease-in-out;
              // max-height: 0;
              max-height: max-content;
              margin: 0;
              .hmenu{
                  margin-left: 40px;
                  .hmenu-a{
                      color: $black1c;
                      font-size: 14px;
                      font-family: 'Poppins-SemiBold';
                      text-decoration: none;
                      &:hover{
                        color: $orange;
                      }
                  }
              }
            }
            .nav-links.show {
              max-height: 300px; 
            }
            .hdrContactbtn {
              height: 36px;
              border-radius: 50px;
              padding: 0 16px;
              font-size: 14px;
              color: $white;
              font-size: 14px;
              font-family: 'Poppins-SemiBold';
          }
          .languageDropdown{
              .p-dropdown-label.p-inputtext.p-placeholder {
                  padding: 0;
                  font-size: 14px;
                  color: $black1c;
                  font-size: 14px;
                  font-family: 'Poppins-SemiBold';
              }
              .p-dropdown{
                  border: 0 !important;
              }
          }
          .menu-icon {
            cursor: pointer;
            display: none;
            color: $black;
          }
          .languageDropdown{
            margin-left:40px;
            margin-right:40px;
            .langUL{
                padding-left: 0;
                list-style: none !important;
                .langLi{
                    list-style: none !important;
                    display: flex;
                    align-items: center;
                    &:marker {
                        display: none;
                    }
                }
            }
        }
          
          }
      }
      .headerLogo {
        width: 150px;
    }
    }

  
  
  
  
  
  
  
  
  
  @media screen and (max-width: 768px) {
    
    .nav-links {
      width: 100%;
      flex-direction: column;
      position: absolute;
      top: 80px; 
      right: 0;
      background-color: transparent;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.3s ease-in-out;
      color: $black1c;
      .hdrContactbtn{}
    }
  
    .nav-links.show {
      max-height: 200px; 
      z-index: 9;
    }
  
    .menu-icon {
      display: block !important;
    }
    ul.nav-links {
      background: #fff !important;
      left: 0;
      width: 100% !important;
      top: 57px !important;
      padding: 33px 0;
      align-items: start !important;
  }
  .navbar .nav-links .hmenu {
    margin-left: 0;
    margin: 6px 0 !important;
    padding: 0 15px;
  }
  .languageDropdown {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding: 10px 15px;
  }
  .hdrContactbtn {
    margin-left: 15px;
    margin-top: 10px;
  }
  }

   
}
.languageSelect{
  .languageSelectBtn{
    background-color: transparent !important;
    border: 0 !important;
    color: $black1c !important;
    &:after{
      content: '';
      font-size: 0 !important;
      border: solid black !important;
      border-width: 0 2px 2px 0 !important;
      display: inline-block !important;
      padding: 4px !important;
      right: -10px;
      position: relative;
      transform: rotate(45deg);
      top: -3px;
    }
    .langImg{
      margin-right: 8px;
    }
    &:hover{
      background-color: transparent !important;
      border: 0 !important;
      color: $black1c !important;
    }
  }
}

.p-overlaypanel-content{
  padding: 0 !important;
  .ddUl{
      padding-left: 0;
      margin: 0;
      .ddLi{
        list-style: none;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        padding: 8px 14px;
        font-family: 'Poppins-Regular';
        font-weight: 400;
        cursor: pointer;
        .langImg{
          margin-right: 8px;
        }
        &:hover{
          background-color: #EFF0FF;
        }
      }
  }
}
.p-overlaypanel{
  &:before{
    display: none;
  }
  &:after{
    display: none;
  }
}