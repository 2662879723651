.container{
    width: 100%;
    max-width: 1140px;
    margin: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.bannerimage{
    width: 100%;
}
.static-content{
    padding-top: 60px;
    padding-bottom: 60px;
    .h1{
        font-size: 24px;
    }
}
.formField{
    padding-bottom: 16px;
    .p-inputtext {
        width: 100%;
        font-size: 14px;
    }
}
.collapsedContent{
    .expandedCategories{
        display: none;
    }
}
.expandedContent{
    .expandedCategories{
        display: block;
    } 
    .mainCategories{
        display: none;
    }
}
.home-section4 {
    .h1{
        font-size: 28px;
    }
    .p-button.p-component.btn {
        height: 36px;
        padding: 0 15px;
        font-size: 14px;
    }
}
.errorMsg {
    color: #F44336;
    margin-bottom: 0;
    margin-top: 0;
    font-size: 12px;
}