[dir="rtl"] {
    .home-section1 {
        .home-banner-right-image {
            left: 0%;
            right: auto;
        }
         .fixello-app-chips .btnflwr {
            left: -30px;
            right: auto;
            transform: rotate(-106deg);
        }
        .homebannerbgimages {
            left: auto;
            right: 0;
            transform: rotate(270deg);
        }
    }
    .home-section2 {
        .what-we-do-sectoion {
            .w-w-d-content-box {
                padding-left: 0;
                padding-right: 62px;
                .listul {
                    padding-left: 0;
                    padding-right: 0;
                    .listli {
                        padding-left: 0;
                        padding-right: 40px;
                        .listIcon {
                            left: auto;
                            right: 0;
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 768px){
            .what-we-do-sectoion {
                .w-w-d-content-box {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
   
    .home-section4{
         .slick-next {
            left: auto;
        }
    }
    .home-section5{
        .p-accordion {
            .p-accordion-header {
                .p-accordion-header-link {
                    .p-accordion-header-text {
                        padding-left: 0;
                        padding-right: 80px;
                        .whyfixelloIcon {
                            left: auto;
                            right: 0;
                        }
                    }
                   .p-accordion-toggle-icon {
                        right: auto;
                        left: 10px;
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
    .home-section6{
        .forClient-content {
            padding-left: 54px;
            padding-right: 76px;
             .h6 .forIcon {
                margin-right: 0;
                margin-left: 12px;
            }
        }
        .forsliderRow {
            padding-left: 0;
            padding-right: 65px;
            background-position: right bottom 50px;
        }
        @media screen and (max-width: 768px){
            .forsliderRow {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    .listul {
        padding-right: 0;
        .listli {
            padding-left: 0;
            padding-right: 40px;
              .listIcon {
                left: auto;
                right: 0;
            }
        }
    }
    .forsliderRow{
        &.forServiceProviders {
            padding-left: 45px;
            padding-right: 40px;
            background-position: left bottom 50px;
            .forClient-content {
                padding-left: 76px;
                padding-right: 54px;
            }
            @media screen and (max-width: 768px){
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
    img.contactICon {
        margin-left: 10px;
    }
    .languageSelect {
        .languageSelectBtn {
            .langImg {
                margin-right: 0;
                margin-left: 8px;
            }
            &:after {
                right: 10px;
            }
        }
    }
    .p-overlaypanel-content {
        .ddUl {
            padding-right: 0;
            img.langImg {
                margin-left: 8px;
            }
        }
    }
     .languageDropdown {
        margin-right: 0 !important;
    }
    .home-section8 {
        .contactDEatil {
            padding-right: 0;
            padding-left: 75px;
        }
    }
    .scroll-to-top-button {
        right: auto;
        left: 130px;
    }
    .whatsapp {
        right: auto;
        left: 0;
    }
    img.productbylogo {
        margin-right: 15px;
    }
    .arTextBold{
        font-family: 'Poppins-Bold';
        font-weight: 700;
    }
}
