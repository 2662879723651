:root {
    --white: #FFFFFF;
    --black: #000000;
    --black1c: #1C1B1F;
    --orange:#FBB03B;
    --blue:#5973C4;
    --grayf7f7:#F7F7F7;
   
}

$white: #FFFFFF;
$black1c: #1C1B1F;
$black: #000000;
$orange:#FBB03B;
$blue:#5973C4;
$grayf7f7:#F7F7F7;