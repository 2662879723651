// BUTTONS
.p-button{
    font-size: 16px;
    font-family: 'Poppins-SemiBold'; 
    background: $orange;
    color: $white;
    border: $orange;
    outline: none !important;
    box-shadow: none !important;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    &:hover,&:active,&:focus,&:focus-visible,&:enabled:hover{
        background: $orange;
        color: $white;
        border: $orange;
    }
}