@font-face {
    font-family: 'Poppins-Regular';
    src: url('./../fonts/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./../fonts/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./../fonts/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./../fonts/Poppins-Bold.ttf') format('truetype');
    font-weight: 700 !important;
}

@font-face {
    font-family: 'Poppins-ExtraBold';
    src: url('./../fonts/Poppins-ExtraBold.ttf') format('truetype');
}

.h1{
    font-size: 44px;
    font-family: 'Poppins-Bold';
    margin: 0px 0px 4px 0px;
    font-weight: 500 ;
    
}
.h2{
    font-size: 28px;
    font-family: 'Poppins-Bold';
    margin: 0px 0px 4px 0px;
    font-weight: 700;
}
.h3{
    font-size: 24px;
    font-family: 'Poppins-Bold';
    margin: 0px 0px 4px 0px;
    font-weight: 700;
}
.h4{
    font-size: 20px;
    margin: 0px 0px 4px 0px;
}
.h6{
    font-size: 16px;
    margin: 0px 0px 4px 0px;
}
.p1{
    font-size: 18px;
    font-family: 'Poppins-Regular';
    margin: 0px 0px 4px 0px;
}
.p2{
    font-size: 16px;
    font-family: 'Poppins-Regular';
    margin: 0px 0px 4px 0px;
}
.p3{
    font-size: 14px;
    font-family: 'Poppins-Regular';
    margin: 0px 0px 4px 0px;
}
.p4{
    margin: 0px 0px 4px 0px;
}