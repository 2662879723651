@import '../../../styles.scss';
.footer-section{
  background-color: $black;
  padding-top: 40px;
  .footermenu{
    .footermenu-li{
      margin: 0 20px;
      .ftrmenuLink{
        font-size: 16px;
        color: $white;
        font-family: 'Poppins-Regular';
        text-decoration: none;
        &:hover{
          color: $orange;
        }
      }
    }
    @media screen and (max-width: 768px){
      padding-left: 0;
       .footermenu-li {
          width: 100%;
          margin: 5px 0;
          text-align: center;
      }
    }
  }
  .ftrleftoverlaybox{
    width: 43%;
    height: 52px;
    top: 194px;
    border-radius: 0px 100px 0px 0px;
    background: #0F0F0F;
    @media screen and (max-width: 768px){
      width: 25%;
    }
  }
  .ftrrightoverlaybox{
    width: 43%;
    height: 52px;
    top: 194px;
    left: 1368px;
    border-radius: 100px 0 0 0px;
    background: #0F0F0F;
    margin-left: auto;
    @media screen and (max-width: 768px){
      width: 25%;
    }
  }
  .socialiconsbox{
    width: 14%;
    @media screen and (max-width: 768px){
      width: 50%;
    }
  }
}
.socialIcon{
  img{
    transition: transform 0.5s ease-in-out;
    &:hover{
      transform: rotate(360deg);
    }
  }
}


.productbylogo{
  width: 120px;
}
.ftrLogo {
  width: 150px;
}